
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { State } from 'vuex-class';
  import { RootState } from '~/store';
  import { IProduct } from '~/interfaces/product';
  import Rating from '~/components/shared/rating.vue';
  import ProductGallery from '~/components/shared/product-gallery.vue';
  import AppLink from '~/components/shared/app-link.vue';
  import AsyncAction from '~/components/shared/async-action.vue';
  import InputNumber from '~/components/shared/input-number.vue';
  import Wishlist16Svg from '~/svg/wishlist-16.svg';
  import Compare16Svg from '~/svg/compare-16.svg';

  export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

  @Component({
    components: {
      Rating,
      AppLink,
      ProductGallery,
      AsyncAction,
      Wishlist16Svg,
      Compare16Svg,
      InputNumber,
    },
  })
  export default class Product extends Vue {
    @Prop({ type: String, required: true }) readonly layout!: ProductLayout;
    @Prop({ type: Object, required: true }) readonly product!: any;
    @State((state: RootState) => state.cart.items) items!: any;

    selectedServices: Array<string> = [];
    @Watch('selectedServices') setSelectedServices(value) {}

    get getProductImages() {
      return this.product.product_images.data;
    }

    get getStocks() {
      // return this.product.stock_quantity == 0 ? 'Sold Out' : 'Order Now';
      return 'Subscribe Now';
    }

    get getLength() {
      const { stock_quantity, no_of_servicing } = this.product;
      const { length: itemsLength } = this.items;
      const { length: selectedSevicesLength } = this.selectedServices;

      return (
        stock_quantity === 0 ||
        itemsLength > 0 ||
        selectedSevicesLength !== no_of_servicing
      );
    }

    get getMonthsFromNowPlusOneYear(): any {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();

      const result: Array<string> = [];

      const startMonthIndex =
        currentDay <= 15 ? currentMonth : currentMonth + 1;

      for (let i = 0; i < 12; i++) {
        const monthIndex = (startMonthIndex + i) % 12;
        const fullYear = currentDate.getFullYear();
        const adjusted = Math.floor((startMonthIndex + i) / 12);
        const year = fullYear + adjusted;
        result.push(`${months[monthIndex]} ${year}`);
      }

      return result;
    }

    quantity: number | string = 1;

    sortByMonth(dates) {
      const monthNames = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
      };

      return dates.sort((a, b) => {
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');

        const monthDiff = monthNames[monthA] - monthNames[monthB];
        if (monthDiff !== 0) {
          return monthDiff;
        } else {
          return parseInt(yearA) - parseInt(yearB);
        }
      });
    }

    addToCart(): Promise<void> {
      if (typeof this.quantity === 'string' || this.quantity < 1) {
        return Promise.resolve();
      }

      const sortedMonth = this.sortByMonth(this.selectedServices);
      return this.$store.dispatch('cart/add', {
        product: this.product,
        quantity: this.quantity,
        selected_services: sortedMonth.join(','),
      });
    }

    getYoutubeEmbed(param) {
      if (param) {
        const split = param.split('=');
        if (split && split.length > 0) {
          let [_, arg] = [null, split[1]];
          return `https://www.youtube.com/v/${arg}`;
        }
      }
    }

    getSelectedServices(item) {
      const { no_of_servicing } = this.product;
      const { length } = this.selectedServices;
      const sameLength = no_of_servicing === length;
      const includes = !this.selectedServices.includes(item);

      console.log(item);

      return sameLength && includes;
    }
  }
