const dataHeaderNavigation: any = [
  {
    name: 'Home',
    url: 'https://dhome.solutions/',
  },
  {
    name: 'Shop',
    url: '/shop/catalog',
  },
  {
    name: 'Price List',
    url: 'https://admin.dhome.solutions/api/static/general/SpecialBonusPackages.pdf',
  },
  {
    name: 'Contact Us',
    url: 'https://dhome.solutions/page-contact',
  },
];

export default dataHeaderNavigation;
