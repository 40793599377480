
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { State } from 'vuex-class';
  import { RootState } from '~/store';
  import { CartItem, CartTotal } from '~/interfaces/cart';
  import AppLink from '~/components/shared/app-link.vue';
  import AsyncAction from '~/components/shared/async-action.vue';
  import Cross10Svg from '~/svg/cross-10.svg';
  import Cross12Svg from '~/svg/cross-12.svg';

  type Type = 'dropdown' | 'offcanvas';

  @Component({
    components: { AppLink, Cross10Svg, Cross12Svg, AsyncAction },
  })
  export default class Dropcart extends Vue {
    @Prop({ type: String, default: () => 'dropdown' }) readonly type!: Type;

    @State((state: RootState) => state.cart.items) items!: any;
    @State((state: RootState) => state.cart.quantity) quantity!: any;
    @State((state: RootState) => state.cart.subtotal) subtotal!: number;
    @State((state: RootState) => state.cart.totals) totals!: CartTotal[];
    @State((state: RootState) => state.cart.total) total!: number;
    @State((state: RootState) => state.offcanvasCart.isOpen) isOpen!: boolean;

    bodyWidth = 0;

    @Watch('isOpen') onIsOpenChange(newValue: boolean) {
      if (newValue) {
        this.open();
      } else {
        this.close();
      }
    }

    open(): void {
      this.hideScrollbar();
    }

    close(): void {
      this.showScrollbar();
    }

    hideScrollbar(): void {
      this.bodyWidth = document.body.clientWidth;

      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${
        document.body.clientWidth - this.bodyWidth
      }px`;
    }

    showScrollbar(): void {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }

    getFirstFiveItems(data) {
      if (data) {
        let items: any = [];
        for (const item of data) {
          items.push(item);
        }

        return items.splice(0, 4);
      }
    }

    getImage(item: any) {
      return this.$url.image(
        'products',
        item.product.preview_id,
        item.product.preview_image
      );
    }

    async removeItem(item) {
      const res = await this.$store.dispatch('cart/remove', { id: item.id });

      if (res) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  }
